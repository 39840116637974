import React, { useState } from "react"
import Modal from "../../layout/modal"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import Button from "../../base/buttons/regular"
import TestSoundButton from "./components/testSoundButton"
import { Link } from "gatsby-plugin-intl"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"

const messages = defineMessages({
  title: "System test",
  verticalTitle: "System test",
  body: `Test your headphones/speakers by clicking on the button below.`,
  continueButton: "Continue",
})

const Audio = () => {
  const [navigationVisible, setNavigationVisible] = useState(true)
  const [widthOfModal, setWidthOfModal] = useState("w-3/5")
  const [playing, setPlaying] = useState(false)
  const formatMessages = useFormatMessage()

  const currentSize = useWindowSize()

  /* Determine if navigation arrows should be displayed based on window width */
  if (["sm", "md"].includes(currentSize) && navigationVisible) {
    setNavigationVisible(false)
    setWidthOfModal("w-full")
  } else if (!["sm", "md"].includes(currentSize) && !navigationVisible) {
    setNavigationVisible(true)
    setWidthOfModal("w-3/5")
  }

  return (
    <Modal
      title={formatMessages(messages.title)}
      mdWidth={widthOfModal}
      navigation={true}
      mobile={!navigationVisible}
      lgWidth="w-2/5"
      sideTitle={formatMessages(messages.verticalTitle)}
      next={`/start${window?.location?.search}`}
      previous={`/introduction${window?.location?.search}`}
    >
      <div className="flex flex-col items-center mt-6">
        <div className="text-sm text-center max-w-xs mb-1">{formatMessages(messages.body)}</div>
        <div className="flex items-center justify-center my-8 cursor-pointer" onClick={() => setPlaying(!playing)}>
          <TestSoundButton playing={playing} onFinishedPlaying={() => setPlaying(false)} />
        </div>
        <Link to={`/start${window?.location?.search}`}>
          <div className="flex justify-center">
            <Button primary className="text-center text-sm">
              {formatMessages(messages.continueButton)}
            </Button>
          </div>
        </Link>
      </div>
    </Modal>
  )
}

export default Audio
