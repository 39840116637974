import React from "react"
import Audio from "../components/test/audio"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const AudioPage = () => {
  return (
    <Layout type="hr" noHeaderOptions>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Test audio</title>
      </Helmet>
      <Audio />
    </Layout>
  )
}

export default AudioPage
